<template>
  <svg :width="width" :height="height" :fill="color"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	viewBox="0 0 512 512" xml:space="preserve">
<path style="fill:#4CBD91;" d="M324.615,295.427c0,0,21.006,17.207,42.224,17.207s36.101-18.051,36.101-18.051
	c7.917,8.762,32.512,21.64,32.512,21.64c68.403,21.745,68.615,61.437,68.615,61.437s0,7.917,0,18.051H373.911
	c-10.134-9.5-25.652-19.212-49.297-26.707c0,0-24.595-12.879-32.512-21.64v-23.012c12.984-6.756,23.54-17.101,30.824-29.874
	L324.615,295.427z"/>
<g>
	<path style="fill:#FEDAAB;" d="M402.941,294.582c0,0-14.884,18.051-36.101,18.051c-21.218,0-42.224-17.207-42.224-17.207
		l-1.689-0.95c3.906-6.65,6.862-13.934,8.867-21.64l3.378,0.845c9.394,4.222,20.057,6.439,31.669,6.439
		c13.512,0,25.652-3.061,36.101-8.551L402.941,294.582L402.941,294.582z"/>
	<path style="fill:#FEDAAB;" d="M402.941,271.57c-10.451,5.489-22.59,8.551-36.101,8.551c-11.612,0-22.273-2.216-31.669-6.439
		l-3.378-0.845c1.161-4.011,2.006-8.233,2.639-12.456c11.19-1.478,22.696-12.034,22.696-21.324c0-10.028-9.712-14.462-21.64-14.462
		v-18.051c0,0,1.267-33.146-16.151-59.325c8.867-4.75,19.001-11.928,29.451-22.379c0,0,46.975,28.923,97.538,28.923v18.051
		c11.928,0,21.64,4.434,21.64,14.462c0,9.29-11.506,19.845-22.696,21.324C441.471,235.89,426.164,259.536,402.941,271.57z"/>
</g>
<g>
	<path style="fill:#A67C52;" d="M366.839,63.509c83.077,0,79.488,90.255,79.488,90.255c-50.564,0-97.538-28.923-97.538-28.923
		c-10.451,10.451-20.584,17.629-29.451,22.379c-6.545-9.712-15.729-18.474-28.502-24.173l0.739-1.794
		C298.646,95.389,317.331,63.509,366.839,63.509z"/>
	<path style="fill:#A67C52;" d="M237.948,177.62c-32.512,32.512-61.437,32.512-61.437,32.512v-3.589c0,0-1.373-33.146,16.15-59.325
		c6.545-9.712,15.623-18.578,28.502-24.173c9.5-4.222,20.902-6.756,34.836-6.756c13.934,0,25.334,2.534,34.836,6.756
		c12.773,5.701,21.957,14.462,28.502,24.173c17.417,26.179,16.151,59.325,16.151,59.325
		C284.923,206.544,237.948,177.62,237.948,177.62z"/>
</g>
<g>
	<path style="fill:#FEDAAB;" d="M292.101,324.351c-10.451,5.489-22.59,8.551-36.101,8.551c-13.512,0-25.652-3.061-36.101-8.551
		c-12.984-6.756-23.54-17.101-30.824-29.874c-3.906-6.65-6.862-13.934-8.867-21.64c-1.161-4.011-2.006-8.233-2.639-12.456
		c-11.19-1.478-22.696-12.034-22.696-21.324c0-10.028,9.712-14.462,21.64-14.462v-14.462c0,0,28.923,0,61.437-32.512
		c0,0,46.975,28.923,97.538,28.923v18.051c11.928,0,21.64,4.434,21.64,14.462c0,9.29-11.506,19.845-22.696,21.324
		c-0.633,4.222-1.478,8.445-2.639,12.456c-2.006,7.706-4.962,14.989-8.867,21.64C315.641,307.25,305.086,317.595,292.101,324.351z"
		/>
	<path style="fill:#FEDAAB;" d="M292.101,347.363c0,0-14.884,18.051-36.101,18.051c-21.218,0-36.101-18.051-36.101-18.051v-23.012
		c10.451,5.489,22.59,8.551,36.101,8.551c13.512,0,25.652-3.061,36.101-8.551V347.363z"/>
</g>
<g>
	<path style="fill:#4CBD91;" d="M138.299,395.71c10.134-9.394,25.546-19.106,49.086-26.707c0,0,24.595-12.879,32.512-21.64
		c0,0,14.884,18.051,36.101,18.051c21.218,0,36.101-18.051,36.101-18.051c7.917,8.762,32.512,21.64,32.512,21.64
		c23.646,7.495,39.163,17.207,49.297,26.707c19.212,17.735,19.318,34.73,19.318,34.73s0,7.917,0,18.051H118.77
		c0-10.134,0-18.051,0-18.051S119.087,413.55,138.299,395.71z"/>
	<path style="fill:#4CBD91;" d="M219.897,324.351v23.012c-7.917,8.762-32.512,21.64-32.512,21.64
		c-23.54,7.6-38.952,17.312-49.086,26.707H7.931c0-10.134,0-18.051,0-18.051s0.211-39.692,68.615-61.437
		c0,0,24.595-12.879,32.512-21.64c0,0,14.884,18.051,36.101,18.051s42.224-17.207,42.224-17.207l1.689-0.95
		C196.357,307.25,206.914,317.595,219.897,324.351z"/>
</g>
<g>
	<path style="fill:#FEDAAB;" d="M145.161,280.121c11.612,0,22.273-2.216,31.669-6.439l3.378-0.845
		c2.006,7.706,4.962,14.989,8.867,21.64l-1.689,0.95c0,0-21.006,17.207-42.224,17.207s-36.101-18.051-36.101-18.051V271.57
		C119.509,277.059,131.649,280.121,145.161,280.121z"/>
	<path style="fill:#FEDAAB;" d="M177.568,260.381c0.633,4.222,1.478,8.445,2.639,12.456l-3.378,0.845
		c-9.395,4.222-20.057,6.439-31.669,6.439c-13.512,0-25.652-3.061-36.101-8.551c-23.224-12.034-38.529-35.68-42.33-63.971
		c-11.19-1.478-22.696-12.034-22.696-21.324c0-10.028,9.712-14.462,21.64-14.462v-18.051c50.564,0,97.538-28.923,97.538-28.923
		c10.451,10.451,20.584,17.629,29.451,22.379c-17.523,26.179-16.15,59.325-16.15,59.325v3.589v14.462
		c-11.928,0-21.64,4.434-21.64,14.462C154.873,248.346,166.379,258.903,177.568,260.381z"/>
</g>
<path style="fill:#A67C52;" d="M220.425,121.251l0.739,1.794c-12.879,5.595-21.957,14.462-28.502,24.173
	c-8.867-4.75-19.001-11.928-29.451-22.379c0,0-46.975,28.923-97.538,28.923c0,0-3.589-90.255,79.488-90.255
	C194.669,63.509,213.353,95.389,220.425,121.251z"/>
<path style="fill:#231F20;" d="M512,377.617c-0.009-1.851-1.08-45.456-73.468-68.737c-8.105-4.286-21.326-12.096-27.66-17.639
	v-15.014c21.559-12.854,36.356-35.061,41.387-62.111c13.864-4.482,23.639-17.352,23.639-27.84c0-11.479-8.265-19.53-21.64-21.766
	v-10.609c0.115-3.566,0.904-46.491-26.169-74.663c-15.089-15.7-35.695-23.661-61.25-23.661c-24.301,0-44.159,7.189-59.02,21.365
	c-9.688,9.242-17.048,21.255-21.934,35.773c-9.077-2.887-19.086-4.357-29.885-4.357c-10.817,0-20.839,1.475-29.901,4.359
	c-12.449-36.911-40.954-57.14-80.939-57.14c-25.554,0-46.161,7.961-61.25,23.661c-27.077,28.176-26.284,71.108-26.169,74.665v10.608
	c-13.375,2.235-21.64,10.287-21.64,21.766c0,10.487,9.775,23.357,23.639,27.84c5.032,27.063,19.84,49.277,41.386,62.112v15.012
	c-6.33,5.537-19.552,13.35-27.661,17.64C1.08,332.162,0.01,375.767,0,377.659v25.982h120.993
	c-9.768,14.356-10.14,26.017-10.153,26.65l-0.001,26.131H401.16V430.39c-0.003-0.579-0.117-5.883-2.961-13.453
	c-1.699-4.525-4.054-8.99-6.978-13.296h120.778v-26.024H512z M227.828,336.35c8.808,2.979,18.238,4.483,28.172,4.483
	c9.937,0,19.37-1.505,28.171-4.48v7.878c-4.122,4.078-14.878,13.253-28.171,13.253c-13.345,0-24.061-9.165-28.172-13.246
	L227.828,336.35L227.828,336.35z M288.413,317.33c-9.652,5.069-20.558,7.641-32.413,7.641c-11.855,0-22.762-2.571-32.442-7.655
	c-11.471-5.969-21.014-15.225-27.646-26.855c-3.483-5.931-6.186-12.533-8.03-19.621l-0.057-0.206
	c-0.978-3.379-1.767-7.117-2.414-11.429l-0.886-5.904l-5.919-0.781c-7.84-1.035-15.803-8.704-15.803-13.46
	c0-6.059,10.493-6.531,13.709-6.531h7.931v-15.365c10.937-2.013,31.858-8.537,54.722-29.706
	c13.207,7.229,48.804,24.661,88.396,26.802l-0.004,18.269h7.931c3.217,0,13.709,0.471,13.709,6.531
	c0,4.756-7.964,12.425-15.804,13.46l-5.92,0.781l-0.885,5.905c-0.646,4.31-1.436,8.047-2.414,11.427l-0.057,0.208
	c-1.844,7.086-4.546,13.688-8.081,19.709C309.454,302.09,299.912,311.346,288.413,317.33z M116.989,283.569
	c8.806,2.979,18.238,4.483,28.172,4.483c10.657,0,20.673-1.729,29.873-5.104c1.1,3.095,2.343,6.095,3.728,8.986
	c-6.375,4.407-20.232,12.77-33.6,12.77c-13.345,0-24.061-9.164-28.172-13.246v-7.889H116.989z M336.965,282.947
	c9.2,3.376,19.216,5.105,29.873,5.105c9.938,0,19.374-1.505,28.172-4.48v7.878c-4.122,4.078-14.878,13.253-28.172,13.253
	c-13.373,0-27.234-8.368-33.603-12.771C334.624,289.033,335.868,286.035,336.965,282.947z M460.036,186.277
	c0,4.756-7.964,12.425-15.803,13.46l-6.015,0.794l-0.808,6.013c-3.508,26.115-17.403,47.249-38.157,58.006
	c-9.652,5.069-20.559,7.641-32.414,7.641c-9.257,0-17.857-1.547-25.625-4.583c0.047-0.232,0.096-0.456,0.142-0.689
	c13.899-4.462,23.703-17.356,23.703-27.86c0-11.478-8.265-19.529-21.64-21.765v-10.617c0.085-2.751,0.571-30.961-13.188-56.649
	c6.745-4.272,13.362-9.404,19.775-15.35c13.207,7.229,48.801,24.66,88.394,26.8l-0.004,18.269h7.931
	C449.543,179.746,460.036,180.217,460.036,186.277z M366.839,71.439c21.033,0,37.77,6.299,49.747,18.722
	c16.707,17.33,20.656,42.693,21.565,55.405c-44.505-2.805-84.782-27.222-85.204-27.48l-5.335-3.286l-4.431,4.431
	c-7.035,7.034-14.321,12.907-21.725,17.562c-5.934-7.264-12.944-13.198-20.951-17.732C310.605,87.463,332.896,71.439,366.839,71.439
	z M287.603,130.288c10.211,4.557,18.675,11.744,25.131,21.325c10.861,16.325,13.797,36.017,14.571,46.735
	c-44.503-2.808-84.776-27.222-85.198-27.48l-5.335-3.286l-4.431,4.431c-19.647,19.646-37.821,26.508-47.832,28.904
	c0.437-9.818,2.809-31.455,14.73-49.266c6.689-9.927,14.894-16.906,25.145-21.358c9.193-4.086,19.534-6.072,31.615-6.072
	C268.079,124.22,278.42,126.207,287.603,130.288z M95.413,90.162c11.977-12.423,28.714-18.722,49.747-18.722
	c33.927,0,56.213,16.008,66.32,47.576c-7.944,4.459-14.95,10.406-20.942,17.774c-7.402-4.654-14.686-10.525-21.719-17.558
	l-4.431-4.431l-5.335,3.286c-0.425,0.261-40.587,24.683-85.204,27.481C74.757,132.85,78.707,107.489,95.413,90.162z M74.589,206.544
	l-0.808-6.013l-6.015-0.794c-7.84-1.035-15.804-8.704-15.804-13.46c0-6.06,10.493-6.531,13.709-6.531h7.931v-18.269
	c39.59-2.142,75.184-19.571,88.389-26.8c6.407,5.942,13.018,11.068,19.756,15.338c-13.812,25.644-13.263,53.847-13.168,56.673
	v10.604c-13.375,2.235-21.64,10.287-21.64,21.765c0,10.504,9.804,23.397,23.703,27.86c0.045,0.234,0.095,0.459,0.142,0.689
	c-7.767,3.036-16.367,4.583-25.625,4.583c-11.855,0-22.762-2.571-32.453-7.661C91.99,253.793,78.097,232.659,74.589,206.544z
	M15.862,377.701c0-0.075,0.351-7.961,7.282-18.144c7.146-10.499,22.628-25.23,55.804-35.777l0.661-0.21l0.615-0.323
	c2.15-1.125,18.035-9.539,28.54-17.771c7.037,6.202,19.984,15.086,36.396,15.086c17.005,0,33.365-9.319,41.654-14.914
	c6.75,9.505,15.297,17.436,25.153,23.336v15.034c-6.334,5.54-19.569,13.36-27.676,17.649c-20.143,6.576-36.61,15.35-49.022,26.11
	H15.862V377.701z M126.697,430.721v-0.03c0-0.005,0-0.012,0.001-0.019L126.697,430.721z M385.298,440.559H126.701v-9.945
	c0.077-1.241,1.26-14.483,16.99-29.088c11.072-10.265,26.592-18.667,46.131-24.976l0.643-0.207l0.599-0.313
	c2.15-1.125,18.035-9.539,28.54-17.771c7.037,6.202,19.984,15.087,36.396,15.087s29.358-8.885,36.395-15.087
	c10.505,8.232,26.39,16.644,28.54,17.771l0.618,0.324l0.665,0.21c19.724,6.252,35.291,14.64,46.269,24.934l0.045,0.042
	c15.83,14.612,16.721,27.994,16.766,29.01L385.298,440.559L385.298,440.559z M496.138,387.78H376.964
	c-12.366-10.814-28.92-19.591-49.273-26.12c-8.109-4.291-21.329-12.103-27.659-17.639v-15.036
	c9.878-5.915,18.43-13.855,25.157-23.333c8.29,5.594,24.647,14.911,41.65,14.911c16.413,0,29.359-8.884,36.397-15.086
	c10.505,8.231,26.39,16.644,28.54,17.771l0.615,0.323l0.661,0.21c60.75,19.312,63.007,52.053,63.087,53.981V387.78z"/>
</svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: '#0f0101'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
