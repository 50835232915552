<template>
  <svg :width="width" :height="height" :fill="color" viewBox="0 0 1024 1024" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M452.266667 955.733333l-384-384c-34.133333-34.133333-34.133333-87.466667 0-121.6l384-384c34.133333-34.133333 87.466667-34.133333 121.6 0l384 384c34.133333 34.133333 34.133333 87.466667 0 121.6l-384 384c-34.133333 34.133333-89.6 34.133333-121.6 0z" fill="#F44336" /><path d="M460.8 697.6c0-6.4 2.133333-12.8 4.266667-19.2 2.133333-6.4 6.4-10.666667 10.666666-14.933333 4.266667-4.266667 10.666667-8.533333 17.066667-10.666667s12.8-4.266667 21.333333-4.266667 14.933333 2.133333 21.333334 4.266667c6.4 2.133333 12.8 6.4 17.066666 10.666667 4.266667 4.266667 8.533333 8.533333 10.666667 14.933333 2.133333 6.4 4.266667 12.8 4.266667 19.2s-2.133333 12.8-4.266667 19.2-6.4 10.666667-10.666667 14.933333c-4.266667 4.266667-10.666667 8.533333-17.066666 10.666667-6.4 2.133333-12.8 4.266667-21.333334 4.266667s-14.933333-2.133333-21.333333-4.266667-10.666667-6.4-17.066667-10.666667c-4.266667-4.266667-8.533333-8.533333-10.666666-14.933333s-4.266667-10.666667-4.266667-19.2z m89.6-98.133333h-76.8L462.933333 277.333333h98.133334l-10.666667 322.133334z" fill="#FFFFFF" /></svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: '#0f0101'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
