<template>
  <svg width="width" :height="height" :fill="color" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
 viewBox="0 0 59 59" xml:space="preserve">
<g>
	<g>
		<path style="fill:#8697CB;" d="M53.566,44.283l-9.552-4.776C42.78,38.89,42,37.628,42,36.248V32.5
			c0.268-0.305,0.576-0.698,0.904-1.162c1.302-1.838,2.286-3.861,2.969-5.984C47.098,24.977,48,23.845,48,22.5v-4
			c0-0.88-0.391-1.667-1-2.217V10.5c0,0,1.187-9-11-9c-12.188,0-11,9-11,9v5.783c-0.609,0.55-1,1.337-1,2.217v4
			c0,1.054,0.554,1.981,1.383,2.517C26.382,29.369,29,32.5,29,32.5v3.655c0,1.333-0.728,2.56-1.899,3.198L18.18,44.22
			C15.603,45.625,14,48.326,14,51.261V54.5h44v-3.043C58,48.419,56.283,45.642,53.566,44.283z"/>
		<path style="fill:#556080;" d="M18.18,44.22l5.946-3.243c-0.034-0.033-0.005-0.043,0.065-0.036l2.91-1.587
			C28.272,38.715,29,37.489,29,36.155V32.5c0,0-1.062-1.275-2.092-3.323h0c0-0.001-0.001-0.002-0.001-0.003
			c-0.135-0.268-0.268-0.551-0.399-0.844c-0.018-0.041-0.036-0.08-0.054-0.121c-0.133-0.303-0.263-0.616-0.386-0.944
			c-0.008-0.021-0.015-0.044-0.023-0.065c-0.108-0.29-0.209-0.589-0.306-0.896c-0.026-0.084-0.052-0.167-0.077-0.251
			c-0.101-0.338-0.196-0.682-0.278-1.038C24.554,24.481,24,23.554,24,22.5v-4c0-0.88,0.391-1.667,1-2.217v-5.648
			C23.587,9.539,21.397,8.5,18,8.5c-9.563,0-10,8-10,8v4.995c-0.526,0.475-1,1.154-1,1.914v3.455c0,0.911,0.479,1.711,1.194,2.174
			c0.863,3.758,3.76,6.463,3.76,6.463v3.157c0,1.151-0.629,2.211-1.64,2.762L3.61,45.622C1.385,46.836,0,49.168,0,51.703V54.5h14
			v-3.239C14,48.326,15.603,45.625,18.18,44.22z"/>
	</g>
	<g>
		<path style="fill:#EFC41A;" d="M57.761,57.5H34.07c-0.955,0-1.55-1.036-1.069-1.861l11.845-20.306c0.478-0.819,1.66-0.819,2.138,0
			L58.83,55.639C59.311,56.464,58.716,57.5,57.761,57.5z"/>
		<path style="fill:#FFFFFF;" d="M46,50.5c-0.552,0-1-0.448-1-1v-8c0-0.552,0.448-1,1-1s1,0.448,1,1v8
			C47,50.052,46.552,50.5,46,50.5z"/>
		<path style="fill:#FFFFFF;" d="M46,54.5c-0.26,0-0.52-0.11-0.71-0.29C45.11,54.02,45,53.76,45,53.5c0-0.26,0.11-0.52,0.29-0.71
			c0.38-0.37,1.04-0.37,1.42,0C46.89,52.98,47,53.24,47,53.5c0,0.26-0.11,0.52-0.29,0.71C46.52,54.39,46.27,54.5,46,54.5z"/>
	</g>
</g>
</svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: '#0f0101'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
