<template>
  <div>
    <div class="rounded h-full bg-white mt-6">
      <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 row-span-1 gap-y-8">
        <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="$router.push({ name: 'Users' })">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2">App Users</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-2 h-32 w-32 rounded-full border-2 bg-blue-200 shadow-md inline-flex" >
            <div class=" flex justify-center items-center w-32 ">
              <usersSvg color="white" :width="'60px'" :height="'60px'"/>
            </div>
          </div>
        </div>
        <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="$router.push({ name: 'ReportedShorts' })">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2">Reported Shots</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-2 h-32 w-32 rounded-full border-2 bg-blue-200 shadow-md inline-flex">
            <div class=" flex justify-center items-center w-32 ">
              <customerSvg color="white" :width="'65px'" :height="'65px'"/>
            </div>
          </div>
        </div>
        <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="$router.push({ name: 'ReportedUsers' })">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2">Reported Users</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-2 h-32 w-32 rounded-full border-2 bg-blue-200 shadow-md inline-flex">
            <div class=" flex justify-center items-center w-32 ">
              <leadSvg color="white" :width="'60px'" :height="'60px'"/>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
import usersSvg from '@/assets/images/svg/usersSvg.vue'
import leadSvg from '@/assets/images/svg/leadSvg.vue'
import customerSvg from '@/assets/images/svg/customerSvg.vue';
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    usersSvg,
    leadSvg,
    customerSvg,
  },
  data () {
    return {
      dashboardData: [],
    }
  },
  created() {
  },
  mounted () {
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
